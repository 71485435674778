var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "camera-realplay-component page-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              { staticStyle: { display: "inline-block", width: "80%" } },
              [
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/camera-index" } },
                      [_vm._v("首页")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("实时预览")]),
              ],
              1
            ),
            _c(
              "a",
              {
                staticClass: "jtl-link pull-right",
                attrs: { href: "/download/video-web-plugin.exe" },
              },
              [_vm._v("插件下载")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c(
            "a-layout",
            { staticClass: "width100" },
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light", width: "300px" } },
                [
                  _c(
                    "a-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.viewTreeLoading,
                          expression: "viewTreeLoading",
                        },
                      ],
                      staticClass: "left-card-wrapper dark-card",
                      style: { height: _vm.playWndHeight / 2 + "px" },
                      attrs: { title: "视图组", bordered: false },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "jtl-dark-query-component" },
                        [
                          _c(
                            "a-popover",
                            {
                              attrs: {
                                trigger: "click",
                                placement: "bottom",
                                visible: _vm.groupPopoverVisible,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "content" },
                                [
                                  _c("span", [_vm._v("添加视图分组")]),
                                  _c("br"),
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入视图组名称" },
                                    model: {
                                      value: _vm.groupInput,
                                      callback: function ($$v) {
                                        _vm.groupInput = $$v
                                      },
                                      expression: "groupInput",
                                    },
                                  }),
                                  _c("br"),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass: "popover-button pull-right",
                                      attrs: {
                                        type: "primary",
                                        "click-prop": _vm.saveGroup,
                                        size: "small",
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass:
                                        "popover-button pull-right popover-cancel-button",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          _vm.groupPopoverVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _vm._v("  "),
                                  _c("div", { staticClass: "clearfix" }),
                                ],
                                1
                              ),
                              _c(
                                "a-tooltip",
                                { attrs: { title: "添加视图分组" } },
                                [
                                  _c("a-button", {
                                    attrs: {
                                      type: "link",
                                      icon: "plus-circle",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.groupPopoverVisible = true
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _vm._v("  "),
                          _c(
                            "a-popover",
                            {
                              attrs: {
                                trigger: "click",
                                placement: "bottom",
                                visible: _vm.groupViewPopoverVisible,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "content" },
                                [
                                  _c("span", [_vm._v("添加视图")]),
                                  _c("br"),
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入视图名称" },
                                    model: {
                                      value: _vm.groupInput,
                                      callback: function ($$v) {
                                        _vm.groupInput = $$v
                                      },
                                      expression: "groupInput",
                                    },
                                  }),
                                  _c("br"),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass: "popover-button pull-right",
                                      attrs: {
                                        type: "primary",
                                        "click-prop": _vm.saveGroupView,
                                        size: "small",
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass:
                                        "popover-button pull-right popover-cancel-button",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          _vm.groupViewPopoverVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _vm._v("  "),
                                  _c("div", { staticClass: "clearfix" }),
                                ],
                                1
                              ),
                              _c(
                                "a-tooltip",
                                { attrs: { title: "添加视图" } },
                                [
                                  _c("a-button", {
                                    attrs: {
                                      type: "link",
                                      icon: "plus",
                                      size: "small",
                                      disabled:
                                        !_vm.selectedGroupView ||
                                        !!(
                                          _vm.selectedGroupView &&
                                          _vm.selectedGroupView.groupId
                                        ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.groupViewPopoverVisible = true
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _vm._v("  "),
                          _c(
                            "a-popover",
                            {
                              attrs: {
                                trigger: "click",
                                placement: "bottom",
                                visible: _vm.groupViewEditPopoverVisible,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "content" },
                                [
                                  _c("span", [_vm._v("编辑视图组")]),
                                  _c("br"),
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入视图组名称" },
                                    model: {
                                      value: _vm.groupInput,
                                      callback: function ($$v) {
                                        _vm.groupInput = $$v
                                      },
                                      expression: "groupInput",
                                    },
                                  }),
                                  _c("br"),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass: "popover-button pull-right",
                                      attrs: {
                                        type: "primary",
                                        "click-prop": _vm.editGroupView,
                                        size: "small",
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                  _c(
                                    "jtl-button",
                                    {
                                      staticClass:
                                        "popover-button pull-right popover-cancel-button",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          _vm.groupViewEditPopoverVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _vm._v("  "),
                                  _c("div", { staticClass: "clearfix" }),
                                ],
                                1
                              ),
                              _c(
                                "a-tooltip",
                                { attrs: { title: "修改" } },
                                [
                                  _c("a-button", {
                                    attrs: {
                                      type: "link",
                                      icon: "edit",
                                      size: "small",
                                      disabled: !_vm.selectedGroupView,
                                    },
                                    on: { click: _vm.editGroupViewClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _vm._v("  "),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认删除?",
                                "ok-text": "确认",
                                "cancel-text": "取消",
                                placement: "bottom",
                              },
                              on: { confirm: _vm.deleteViewClick },
                            },
                            [
                              _c(
                                "a-tooltip",
                                { attrs: { title: "删除" } },
                                [
                                  _c("a-button", {
                                    attrs: {
                                      type: "link",
                                      icon: "delete",
                                      size: "small",
                                      disabled: !_vm.selectedGroupView,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v("  "),
                          _c(
                            "a-tooltip",
                            { attrs: { title: "保存" } },
                            [
                              _c("a-button", {
                                attrs: {
                                  type: "link",
                                  icon: "save",
                                  size: "small",
                                  disabled:
                                    !_vm.selectedGroupView ||
                                    !(
                                      _vm.selectedGroupView &&
                                      _vm.selectedGroupView.groupId
                                    ),
                                },
                                on: { click: _vm.saveGroupViewRelation },
                              }),
                            ],
                            1
                          ),
                          _vm._v("  "),
                        ],
                        1
                      ),
                      _c(
                        "a-tree",
                        {
                          staticClass: "jtl-tree",
                          attrs: {
                            "default-expand-all": true,
                            "tree-data-simple-mode": "",
                            "tree-data": _vm.viewTreeData,
                            "replace-fields": { key: "id", title: "name" },
                            "selected-keys": _vm.groupViewSelectedKeys,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function (item) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "block",
                                      on: {
                                        dblclick: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.startViewPreview(item)
                                        },
                                        click: function ($event) {
                                          return _vm.groupViewOnClick(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("a-icon", {
                            attrs: { slot: "switcherIcon", type: "down" },
                            slot: "switcherIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.cameraTreeLoading,
                          expression: "cameraTreeLoading",
                        },
                      ],
                      staticClass:
                        "left-card-wrapper dark-card jtl-dark-query-component",
                      style: { height: _vm.playWndHeight / 2 + "px" },
                      attrs: { title: "监控", bordered: false },
                    },
                    [
                      _c("a-input-search", {
                        attrs: { placeholder: "搜索" },
                        on: { change: _vm.debounceFilterTree },
                        model: {
                          value: _vm.searchValue,
                          callback: function ($$v) {
                            _vm.searchValue = $$v
                          },
                          expression: "searchValue",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "tree-search-wrapper" },
                        [
                          _c(
                            "a-tree",
                            {
                              staticClass: "jtl-tree",
                              attrs: {
                                "default-expand-all": true,
                                "expanded-keys": _vm.cameraExpandedKeys,
                                "tree-data-simple-mode": "",
                                "tree-node-filter-prop": "title",
                                "tree-data": _vm.cameraTreeData,
                                "selected-keys": _vm.cameraSelectedKeys,
                              },
                              on: {
                                "update:expandedKeys": function ($event) {
                                  _vm.cameraExpandedKeys = $event
                                },
                                "update:expanded-keys": function ($event) {
                                  _vm.cameraExpandedKeys = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function (item) {
                                    return [
                                      _c(
                                        "jtl-tooltip-component",
                                        {
                                          attrs: {
                                            title: item.title,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getHighLightHtml(
                                                  item.title,
                                                  _vm.searchValue
                                                )
                                              ),
                                            },
                                            on: {
                                              dblclick: function ($event) {
                                                return _vm.startPreview(item)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("a-icon", {
                                attrs: { slot: "switcherIcon", type: "down" },
                                slot: "switcherIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "jtl-video-wrapper" },
                  [
                    _c("jtl-video-component", {
                      ref: "jtlVideo",
                      on: {
                        resize: function ($event) {
                          _vm.playWndHeight = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }