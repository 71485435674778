




































































































































// @ts-nocheck
import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import CameraPlayService, { CameraLocationTreeEntityModel } from './service/camera-play-service';

@Component
export default class CameraRealplayComponent extends BaseComponent {
    playWndHeight: number = 0;// 播放窗口高度
    viewTreeData: Array<any> = [];// 视图组树形数据
    cameraTreeData: Array<any> = [];// 监控点树形数据
    searchValue: string = '';// 搜素关键词
    cameraTreeLoading: boolean = true;
    viewTreeLoading: boolean = true;
    groupInput: string = null;
    selectedGroupView: any = null;// 选中的视图/视图组
    groupPopoverVisible: boolean = false;
    groupViewPopoverVisible: boolean = false;
    groupViewEditPopoverVisible: boolean = false;
    saveAsPopoverVisible: boolean = false;
    cameraSelectedKeys: Array<string> = null;
    cameraExpandedKeys: Array<string> = [];
    groupViewSelectedKeys: Array<string> = null;
    debounceFilterTree: Function = null;
    saveAsGroupId: string = '';

    created() {
        this.debounceFilterTree = _.debounce(this.onSearchValueChange.bind(this), 500);
    }

    mounted() {
        this.initGroupViewTree();
        CameraPlayService.getCameraTreeData().then(res => {
            this.cameraTreeData = res;
        }).finally(() => this.cameraTreeLoading = false);
    }

    get TrimSearchValue() {
        return _.toUpper(_.trim(this.searchValue));
    }
    onSearchValueChange() {
        if (!this.TrimSearchValue) {
            this.cameraExpandedKeys = [];
            return;
        }
        this.cameraExpandedKeys = [];
        _.forEach(this.cameraTreeData, (item: CameraLocationTreeEntityModel) => {
            this.initSearchExpandedKeys(item);
        });
    }

    /**
     * 初始化视图
     */
    initGroupViewTree() {
        CameraPlayService.getCameraGroupTreeData().then(res => {
            this.viewTreeData = res;
        }).finally(() => this.viewTreeLoading = false);
    }

    /**
     * 通过视图id预览
     */
    startViewPreview(viewModel) {
        if (viewModel && viewModel.groupId) {
            this.$refs.jtlVideo.stopAllPreview();
            let hasInitLayout = false;
            if (this.$refs.jtlVideo.cameraModel.isInit) {
                this.$refs.jtlVideo.setLayout(viewModel.layout);
                hasInitLayout = true;
            }
            this.groupViewSelectedKeys = [viewModel.id];
            this.selectedGroupView = viewModel;
            CameraPlayService.getFacilityAttrsByViewId(viewModel.id).then(async(res) => {
                if (res && res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        await this.openCamera(res[i].facilityId, res[i].attrMap, res[i].index);
                        if (!hasInitLayout) {
                            this.$refs.jtlVideo.setLayout(viewModel.layout);
                            hasInitLayout = true;
                        }
                    }
                }
            });
        }
    }

    /**
     * 打开预览
     */
    startPreview(facilityModel) {
        if (facilityModel.type === 'LOCATION') {
            this.showMessageInfo('空间信息不能预览');
            return;
        }
        const facilityId = facilityModel.id;
        this.cameraSelectedKeys = [facilityId];
        CameraPlayService.getFacilityAttrs(facilityId).then(async(res) => {
            this.openCamera(facilityId, _.get(res, '[0].attrMap'));
        });
    }

    /**
     * 打开摄像头预览
     */
    private async openCamera(facilityId: string, deviceAttr: any, winId?: string) {
        try {
            await this.$refs.jtlVideo.startPreviewByFacilityId(facilityId, deviceAttr, winId);
        } catch (e) {
            this.showMessageWarning(e.message);
        }
    }

    groupViewOnClick(viewModel: any) {
        const viewId = viewModel.id;
        if (this.groupViewSelectedKeys && this.groupViewSelectedKeys.indexOf(viewId) > -1) {
            this.groupViewSelectedKeys = [];
            this.selectedGroupView = null;
        } else {
            this.groupViewSelectedKeys = [viewId];
            this.selectedGroupView = viewModel;
        }
    }

    /**
     * 保存视图组
     */
    saveGroup(id?: string) {
        if (!this.checkGroupViewInput()) {
            return;
        }
        const params = { name: this.groupInput };
        if (id) {
            params.id = id;
        }
        return CameraPlayService.saveGroup(params).then(res => {
            this.groupPopoverVisible = false;
            this.groupInput = '';
            this.initGroupViewTree();
        });
    }

    /**
     * 保存视图
     */
    saveGroupView(id?: string) {
        if (!this.checkGroupViewInput()) {
            return;
        }
        if (!this.selectedGroupView) {
            this.showMessageWarning('请先选择视图');
            return;
        }
        const params = { name: this.groupInput, groupId: this.selectedGroupView.id };
        if (id) {
            params.id = id;
        }
        return CameraPlayService.saveGroupView(params).then(res => {
            this.groupViewPopoverVisible = false;
            this.groupInput = '';
            this.initGroupViewTree();
        });
    }

    /**
     * 打开编辑视图/视图组浮层
     */
    editGroupViewClick() {
        this.groupInput = this.selectedGroupView.name;
        this.groupViewEditPopoverVisible = true;
    }

    /**
     * 编辑视图/视图组
     */
    editGroupView() {
        if (!this.checkGroupViewInput()) {
            return;
        }
        if (this.selectedGroupView.groupId) {
            // return this.saveGroupView(this.selectedGroupView.id).then(() => this.groupViewEditPopoverVisible = false);
            const params = { id: this.selectedGroupView.id, name: this.groupInput };
            if (this.selectedGroupView.groupId) {
                params.groupId = this.selectedGroupView.groupId;
            }
            return CameraPlayService.saveGroupView(params).then(res => {
                this.groupViewEditPopoverVisible = false;
                this.groupInput = '';
                this.initGroupViewTree();
            });
        }
        return this.saveGroup(this.selectedGroupView.id).then(() => {
            this.groupViewEditPopoverVisible = false;
            this.groupInput = '';
            this.initGroupViewTree();
        });
    }

    /**
     * 另存为
     */
    saveAsGroupView() {
        if (!this.checkGroupViewInput()) {
            return;
        }
        if (!this.saveAsGroupId) {
            this.showMessageWarning('请选择分组');
            return false;
        }
        return new Promise((resolve, reject) => {
            CameraPlayService.getFacilityAttrsByViewId(this.selectedGroupView.id).then(res => {
                const params = { name: this.groupInput, groupId: this.saveAsGroupId };
                CameraPlayService.saveGroupView(params).then(res => {
                    this.saveAsPopoverVisible = false;
                    this.groupInput = '';
                    this.saveAsGroupId = null;
                    this.initGroupViewTree();
                });
            });
        });
    }

    /**
     * 保存视图关系
     */
    saveGroupViewRelation() {
        const cameraModel = this.$refs.jtlVideo.cameraModel;
        const facilityIds = cameraModel.getWndFacilityMap();
        return CameraPlayService.saveGroupViewRelation(this.selectedGroupView.id, facilityIds, cameraModel.layout).then(res => {
            for (let i = 0; i < this.viewTreeData.length; i++) {
                const groupView = _.find(this.viewTreeData[i].children, item => item.id === this.selectedGroupView.id);
                if (groupView) {
                    groupView.layout = cameraModel.layout;
                    break;
                }
            }
            this.showMessageSuccess('保存成功');
        });
    }

    /**
     * 删除视图/视图组
     */
    deleteViewClick() {
        if (this.selectedGroupView.groupId) {
            return CameraPlayService.deleteGroupView(this.selectedGroupView.id).then(res => {
                this.initGroupViewTree();
            });
        }
        return CameraPlayService.deleteGroup(this.selectedGroupView.id).then(res => {
            this.initGroupViewTree();
        });
    }

    private checkGroupViewInput() {
        if (!_.trim(this.groupInput)) {
            this.showMessageWarning('请输入名称');
            return false;
        }
        return true;
    }

    private initSearchExpandedKeys(treeItem: CameraLocationTreeEntityModel) {
        if (treeItem.type === 'FACILITY' && !this.TrimSearchValue || _.toUpper(treeItem.title).indexOf(this.TrimSearchValue) > -1) {
            this.getParentKey(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                this.initSearchExpandedKeys(item);
            });
        }
    }
    private getParentKey(treeItem) {
        if (treeItem && treeItem.parent) {
            this.cameraExpandedKeys.push(treeItem.parent.key);
            this.getParentKey(treeItem.parent);
        }
    }
}

